var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-cell',[_c('template',{slot:"title"},[_c('span',{staticStyle:{"color":"#57c4b7"}},[_vm._v("*温馨提示：请选择成员来进行门诊缴费")])])],2),_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":"没有更多了"},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('van-cell-group',_vm._l((_vm.list),function(item){return _c('van-cell',{key:item + 'FCardL',attrs:{"title":item.userName,"value":""},on:{"click":function($event){return _vm.onclickselect(
            item.userID,
            item.userName,
            item.outPatientId,
            item.barCode,
            item.idCard,
            item.phone
          )}}})}),1)],1),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"font-size":"12px","color":"#D3D3D3","margin":"1% 40%","width":"auto","height":"40px"}},[_c('span',[_vm._v("©拓实银医")])])
}]

export { render, staticRenderFns }