<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color:#57c4b7;">*温馨提示：请选择成员来进行门诊缴费</span>
      </template>
    </van-cell>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell-group>
        <van-cell
          v-for="item in list"
          :key="item + 'FCardL'"
          :title="item.userName"
          value=""
          @click="
            onclickselect(
              item.userID,
              item.userName,
              item.outPatientId,
              item.barCode,
              item.idCard,
              item.phone
            )
          "
        ></van-cell>
      </van-cell-group>
    </van-list>
    <div
      style="font-size: 12px; color: #D3D3D3; margin: 1% 40%; width: auto; height: 40px;"
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant"; //弹出框
import ajax from "../../lib/ajax";
import store from "../../store";
// var lists;
export default {
  data() {
    return {
      list: [],
      // list: [{
      //   PatientName: "李子",
      //   CardNumber: "123456467",
      //   Idcard:"492527199505142222",
      // },
      // {
      //   PatientName: "王往",
      //   CardNumber: "5445645646",
      //   Idcard:"532527199505142222",
      // }],
      loading: false,
      finished: false,
    };
  },

  methods: {
    async onLoad() {
      // 刘工openId
      // "oA1lZs3_6fstbC__l7GU-rfJFoVc"//
      // 孟主任openId
      // "oA1lZsye1z8E4MVSI84p8swSsumQ"//
      // zhou
      // "oA1lZs2v6Fk1ggF676z9IHoLgh6k"//
      var wxopenid = store.getters["wechat/openid"];
      await ajax
        .get("/Api/CardService/WeChatGetMembers?input=" + wxopenid)
        .then((res) => {
          const lists = res.data.result;
          this.list = lists;

          console.log(lists);
        })
        .catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        });
      // 异步更新数据
      setTimeout(() => {
        for (let i = 0; i < 0; i++) {
          this.list.push(this.list.length);
        }
        // 加载状态结束
        this.loading = false;

        // 数据全部加载完成
        if (this.list.length == this.list.length) {
          this.finished = true;
        }
      }, 500);
    },
    updateL(idcard, cardNo) {
      ajax
        .get(
          "/Api/HealthCardService/GetHealthQrCode?cardid=" +
            cardNo +
            "&idcard=" +
            idcard +
            "&codetype=0"
        )
        .then((res) => {
          var qrCodeText = res.data.result.rsp.qrCodeText;
          ajax
            .get(
              `/Api/HealthCardService/UploadData?scene=0201062&dept=&qrcode=${qrCodeText}`
            )
            .then(() => {
              console.log("上传成功");
              // Toast(res.errMsg);
            })
            .catch(() => {
              console.log("上传失败");
              // Toast("上传失败");
            });
        })
        .catch(() => {
          console.log("获取二维码失败");
          // Toast("获取二维码失败");
        });
    },
    async onclickselect(userId, userName, patientId, cardNo, idCard, phone) {
      localStorage.setItem("idCard", idCard);
      localStorage.setItem("userName", userName);
      localStorage.setItem("cardNo", cardNo);
      localStorage.setItem("userId", userId);
      store.commit("store/setUserId", userId);

      // // alert(Idcard);
      store.commit("store/setUserName", userName);
      // store.commit("store/setPatientid",patientId);
      store.commit("store/setCardNo", cardNo);
      store.commit("store/setIdcard", idCard);
      store.commit("store/setPhone", phone);
      this.$store.dispatch("setsyncu", userName);
      this.updateL(idCard, cardNo);
      this.$router
        .replace({
          //path:'/DepS/:Idcard',
          path: "/MPayL",
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
